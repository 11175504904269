import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from "../src/gatsby-theme-docz/components/Button/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pflegeeinrichtungen"
    }}>{`Pflegeeinrichtungen`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Kunde`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AWO Werkstatt für Behinderte in Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`650 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CARITAS Behindertenwerkstatt Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CARITAS Altenpflegeheim Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`290 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CARITAS Altenpflegeheim Crostwitz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LEBENSHILFE Leipzig, Behindertenwerkstatt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`190 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LEBENSHILFE Grimma, Behindertenwohnheim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`195 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LEBENSHILFE Döbeln, Behindertenwohnheim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ALTENPFLEGEHEIM Villa-Auenwald, Böhlitz-Ehrenberg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SENIORENHOF Leipzig-Plagwitz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`135 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Behindertenwohnheim Riebeckstraße, Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heimverbund Leipzig, Altenpflegeheim Borna`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`440 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pflegedienst Friese, Altenpflegeheim Wurzen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`145 T€`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Legende: H=Heizung, S=Sanitär, L=Lüftungsinstallation, K=Kälte- bzw. Klimainstallationen, MSR=Regeltechnische Anlagen`}</strong></p>
    <Button mdxType="Button" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      